const NatureMade = () => {
    const logo = 'https://www.naturemade.com/cdn/shop/t/355/assets/nm-logo-header@2x.png?v=102224942129678760711727119348'
    
    const baseUrl = 'https://www.naturemade.com/'

    const name = 'Nature Made'

    const searchUrl = (query) => {
        return 'https://www.naturemade.com/search?q=' + encodeURIComponent(query)
    }

    const filterResults = (results) => {
        return results.filter(result => !result.productLink.startsWith('/collections'))
    }

    const formatResults = (results) => {
        for (const result of results) {
            result.productLink = baseUrl + result.productLink
        }
    }

    return { logo, searchUrl, filterResults, formatResults, name }
}

const Stores = [NatureMade]

export default Stores;