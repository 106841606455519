import { MultiOnClient } from "multion";

const MULTI_KEY = process.env.REACT_APP_MULTI_KEY

export const MockedSearch = () => {
    const search = async (url) => {
        return [
            {
              name: 'Max Q-10',
              description: 'Support cellular energy plus heart and brain health with this potent formula featuring a pure, trans-form variety of CoQ10 that is bioidentical to CoQ10 produced naturally in your body, along with antioxidant-rich BioPerine®^^ to boost CoQ10 absorption up to 30%.*',
              imageSrc: 'https://www.stopagingnow.com/cdn/shop/files/1_6f20d30e-6d70-46d4-ac66-b4c6a736e3b3.webp?v=1724709979&width=606',
              priceCents: 2799,
              productLink: 'https://www.stopagingnow.com/products/purzanthin-ultra-astaxanthin-12-mg-30-softgels-sa26141'
            },
            {
              name: 'Multi 360',
              description: 'This advanced formula is a step up from our Multi360® Complete, offering 28 essential vitamins, minerals, herbs and nutrients with antioxidant power equal to 10 servings of fruits and vegetables for optimal wellness and healthy aging. *',
              imageSrc: 'https://www.stopagingnow.com/cdn/shop/files/1_7d6d5372-07d9-49f0-826d-363dec70def0.webp?v=1724709880&width=606',
              priceCents: 3999,
              productLink: 'https://www.stopagingnow.com/products/purzanthin-ultra-astaxanthin-12-mg-30-softgels-sa26141'
            },
            {
                name: 'Multi 360',
                description: 'This advanced formula is a step up from our Multi360® Complete, offering 28 essential vitamins, minerals, herbs and nutrients with antioxidant power equal to 10 servings of fruits and vegetables for optimal wellness and healthy aging. *',
                imageSrc: 'https://www.stopagingnow.com/cdn/shop/files/1_7d6d5372-07d9-49f0-826d-363dec70def0.webp?v=1724709880&width=606',
                priceCents: 3999,
                productLink: 'https://www.stopagingnow.com/products/purzanthin-ultra-astaxanthin-12-mg-30-softgels-sa26141'
              },
              {
                name: 'Multi 360',
                description: 'This advanced formula is a step up from our Multi360® Complete, offering 28 essential vitamins, minerals, herbs and nutrients with antioxidant power equal to 10 servings of fruits and vegetables for optimal wellness and healthy aging. *',
                imageSrc: 'https://www.stopagingnow.com/cdn/shop/files/1_7d6d5372-07d9-49f0-826d-363dec70def0.webp?v=1724709880&width=606',
                priceCents: 3999,
                productLink: 'https://www.stopagingnow.com/products/purzanthin-ultra-astaxanthin-12-mg-30-softgels-sa26141'
              },
              {
                name: 'Multi 360',
                description: 'This advanced formula is a step up from our Multi360® Complete, offering 28 essential vitamins, minerals, herbs and nutrients with antioxidant power equal to 10 servings of fruits and vegetables for optimal wellness and healthy aging. *',
                imageSrc: 'https://www.stopagingnow.com/cdn/shop/files/1_7d6d5372-07d9-49f0-826d-363dec70def0.webp?v=1724709880&width=606',
                priceCents: 3999,
                productLink: 'https://www.stopagingnow.com/products/purzanthin-ultra-astaxanthin-12-mg-30-softgels-sa26141'
              },
              {
                name: 'Multi 360',
                description: 'This advanced formula is a step up from our Multi360® Complete, offering 28 essential vitamins, minerals, herbs and nutrients with antioxidant power equal to 10 servings of fruits and vegetables for optimal wellness and healthy aging. *',
                imageSrc: 'https://www.stopagingnow.com/cdn/shop/files/1_7d6d5372-07d9-49f0-826d-363dec70def0.webp?v=1724709880&width=606',
                priceCents: 3999,
                productLink: 'https://www.stopagingnow.com/products/purzanthin-ultra-astaxanthin-12-mg-30-softgels-sa26141'
              }
        ]
    }
    
   return { search }
}

export const MultiOnSearch = () => {
    const search = async (url) => {
        const multion = new MultiOnClient({ apiKey: MULTI_KEY})
        const retrieveResponse = await multion.retrieve({
            cmd: "Get all of the products on this page including the name, the description, the image source, the price in cents (1099 instead of 10.99), and the link to the product",
            url: url,
            fields: ["name", "description", "imageSrc", "priceCents", "productLink"],
            scrollToBottom: true,
            useProxy: true
        });
        
        return retrieveResponse.data;
    }
    
   return { search }
}