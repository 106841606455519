import { useState, createContext, useContext } from 'react'

// Create a context
const WalleContext = createContext();

// Provide context to your app
export const WalleProvider = ({ children }) => {
    const [walleUserId, setWalleUserId] = useState(null);
    const [cardLastFour, setCardLastFour] = useState(null)
    const [sessionToken, setSessionToken] = useState(null)
  
    const appId = process.env.REACT_APP_WALLE_APP_ID
    const apiKey = process.env.REACT_APP_WALLE_API_KEY 

    console.log(walleUserId)

    return (
    <WalleContext.Provider value={{ walleUserId, appId, apiKey, setWalleUserId, cardLastFour, setCardLastFour, sessionToken, setSessionToken }}>
        {children}
    </WalleContext.Provider>
    );
};

export const useWalle = () => useContext(WalleContext);