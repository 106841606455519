import { useWalle } from "./WalleProvider";
import { useEffect } from "react";
import { WALLE_BASE } from "./Urls";

// TODO clear cart after purchase is done
const WallePurchase = ({ purchaseDescription, expectedPurchaseAmountCents }) => {
    const { appId, walleUserId, setSessionToken } = useWalle();
  
    // const onComplete = (token) => {
    //     console.log(token)
    // };

    const purchaseDescriptionParam = encodeURIComponent(purchaseDescription)
  
    const link = `${WALLE_BASE}/start_purchase?app_id=${appId}&purchase_description=${purchaseDescriptionParam}&expected_purchase_amount_cents=${expectedPurchaseAmountCents}`;
    const style = {
      width: '500px',
      minHeight: '100vh',
      margin: '0 auto',
      border: 'none',
      background: 'inherit'
    };

    const containerStyle = {
        margin: '0 auto',
        textAlign: 'center'
    }
  
    useEffect(() => {
      // Function to handle messages from the iframe
      function handleMessage(event) {
        // Check if the message is from the expected origin and the message type is correct
        if (event.data?.type === 'newSessionToken') {
          setSessionToken(event.data.data.token);
        }
      }
  
      // Add the event listener only once
      window.addEventListener('message', handleMessage);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('message', handleMessage);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walleUserId]); // Depend on walleUserId to ensure the effect runs correctly
  
    return(
        <div style={containerStyle}>
            <iframe title="Walle Purchase" src={link} style={style} />
        </div>
    )
  };

export default WallePurchase