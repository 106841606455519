const AssistantChat = ({ content }) => {
    const style = {
      backgroundColor: '#f0f0f0', // Light background for assistant
      padding: '12px 16px',
      borderRadius: '15px',
      marginBottom: '10px',
      maxWidth: '60%', // Restricts the message width
      textAlign: 'left',
      alignSelf: 'flex-start', // Aligns the message to the left
      fontSize: '16px',
      color: '#000', // Dark text for the assistant
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adds a slight shadow for 3D effect
      wordBreak: 'break-word',
    };
  
    return <div style={style}>{content}</div>;
};


export const AssistantLoading = () => {
  // Dot animation styling
  const dotStyle = {
    display: 'inline-block',
    animation: 'loadingDots 1s infinite',
    marginRight: '4px',
    fontSize: '20px',
  };

  // Loader content with animated dots
  const loadingContent = (
    <>
      <span style={{ ...dotStyle, animationDelay: '0s' }}>•</span>
      <span style={{ ...dotStyle, animationDelay: '0.2s' }}>•</span>
      <span style={{ ...dotStyle, animationDelay: '0.4s' }}>•</span>

      {/* Add keyframes for animation */}
      <style>
        {`
          @keyframes loadingDots {
            0% { opacity: 0.2; }
            50% { opacity: 1; }
            100% { opacity: 0.2; }
          }
        `}
      </style>
    </>
  );

  return <AssistantChat content={loadingContent} />;
};

export default AssistantChat