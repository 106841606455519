// 1) AI that can chat

// 2) Chat interface

// 3) Show Walle

// 4) Search

// 5) Shop

// messages tied to chat id



// AI decides what to do
// Do it
// Display message

import './App.css';
import { useState, useEffect, useRef } from 'react'
import { TextField, IconButton, Box, Button } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useSlidingPane } from './SlidingPaneProvider';
import UserMessage from './UserMessage';
import AssistantChat from './AssistantMessage';
import { useSteps } from './StepsProvider';
import SlidingPane from './SlidingPane';
import OpenAI from 'openai';
import ToolCall from './ToolCall';

const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true })

const SearchTool = () => {
    const display = "Searching..."

    const llmSchema = {
        "type": "function",
        "function": {
            "name": "search",
            "description": "Search online store for what the customer is looking for",
            "parameters": {
                "type": "object",
                "properties": {
                    "query": {
                        "type": "string",
                        "description": "The search term"
                    }
                },
                "required": ["query"],
                "additionalProperties": false
            }
        }
    }

    const perform = (query) => {
        return [
            {
              description: 'Support cellular energy plus heart and brain health with this potent formula featuring a pure, trans-form variety of CoQ10 that is bioidentical to CoQ10 produced naturally in your body, along with antioxidant-rich BioPerine®^^ to boost CoQ10 absorption up to 30%.*',
              imageSrc: 'https://www.stopagingnow.com/media/catalog/product/2/0/200_l.png?optimize=low&fit=bounds&height=606&width=606',
              priceCents: 2799
            },
            {
              description: 'This advanced formula is a step up from our Multi360® Complete, offering 28 essential vitamins, minerals, herbs and nutrients with antioxidant power equal to 10 servings of fruits and vegetables for optimal wellness and healthy aging. *',
              imageSrc: 'https://www.stopagingnow.com/media/catalog/product/1/0/10859_l.png?optimize=low&fit=bounds&height=606&width=606',
              priceCents: 3999
            }
        ]
    }

    return { display, llmSchema, perform }
}

const CheckoutTool = () => {
    const display = "Making purchase."

    const llmSchema =  {
        "type": "function",
        "function": {
            "name": "checkout",
            "description": "Show the user a checkout page so they can finalize the purchase",
            "parameters": {
                "type": "object",
                "properties": {},
                "required": [],
                "additionalProperties": false
            }
        }
    }

    const perform = (product_url) => {
        return true
    }

    return { display, llmSchema, perform }
}

const WalleOnboardingTool = () => {
    const display = "Please create a Walle account so I can make purchases on your behalf."

    const llmSchema = {
        "type": "function",
        "function": {
            "name": "walle_onboarding",
            "description": "Collect a users cc information to shop on their behalf",
            "parameters": {}
        }
    }

    const perform = () => {
        return true
    }

    return { display, llmSchema, perform }
}

const Tools = () => {
    const tools = [SearchTool, CheckoutTool, WalleOnboardingTool]

    const llmSchemas = tools.map(tool => {
        const { llmSchema } = tool()
        return llmSchema
    })

    const findByFunctionName = (name) => {
        return tools.find(tool => {
            const { llmSchema } = tool()
            return llmSchema.function.name === name
        })
    }

    return { llmSchemas, findByFunctionName }

}

const DEFAULT_SYSTEM_PROMPT = `
You are a personal shopping assistant that is shopping for supplements. 
You first need to search for supplements based on what the customer tells you.
Once the customer asks to buy an item you need to prompt them to complete Walle Onboarding. You will be notified once Walle Onboarding has been complete.
Only when Walle Onboarding is complete show them the final checkout page.
You will get a notification if a purchase succeeds or fails.
`

const OpenAiCompletion = async (messages) => {  
    const { llmSchemas } = Tools()
    
    let completion
    try {
        completion =  await openai.chat.completions.create({
        model: 'gpt-4o',
        messages: [{ role: 'system', content: DEFAULT_SYSTEM_PROMPT}, ...messages],
        tools: llmSchemas
        })
    } catch (error) {
        console.log(error)
    }

    return completion.choices[0].message
}


const StartingOptions = ({ options }) => {
  const { addUserChat } = useSteps()

  const style = {
    backgroundColor: '#f0f0f0', // Light background for assistant
    padding: '12px 16px',
    borderRadius: '15px',
    marginBottom: '10px',
    maxWidth: '60%', // Restricts the message width
    textAlign: 'left',
    alignSelf: 'flex-start', // Aligns the message to the left
    fontSize: '16px',
    color: '#000', // Dark text for the assistant
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adds a slight shadow for 3D effect
    wordBreak: 'break-word',
  };
  
  return(
    <div style={style}>
      <p>What can I help you buy today?</p>
      <Button 
        onClick={() => addUserChat('multivitamins')}
        sx={{
          display: 'block',
          backgroundColor: 'gray',
          color: '#fff',
          fontSize: '12px',
          fontWeight: 'bold',
          textTransform: 'none',
          borderRadius: '24px',
          padding: '10px 36px',
          marginTop: '16px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            backgroundColor: '#3700B3',
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
          },
          '&:active': {
            backgroundColor: '#5600E8',
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
          },
        }}
        >
          MultiVitmains
        </Button>
    </div>
  )
}

const StepRenderer = (step) => {
  const { state, id } = step

  switch(step.component) {
    case 'ToolCall':
      return <ToolCall state={state} stepId={id} />
    case 'UserMessage':
      return <UserMessage message={state.message} />
    case 'AssistantMessage':
      return <AssistantChat content={state.content} />
    case 'StartingOptions':
      return <StartingOptions />
    default:
      return null
    
  }
}

const PersonalShopper = () => {
    const hasRun = useRef(false);
    const { isOpen} = useSlidingPane();

    const { steps, addStep, addUserChat, addAssistantChat, lastStep, llmChat, newAddToolCall } = useSteps();

    const _lastStep = lastStep()

    useEffect(() => {
        const nextStep = async () => {
            const runLlm = async() => {
                const response = await OpenAiCompletion(llmChat())

                if (response.tool_calls) {
                    newAddToolCall(response)
                } else {
                    addAssistantChat(response.content)
                }
            }

            if (!_lastStep) { return }

            switch (_lastStep.type) {
                // system message handled same as user message
                case 'system_message':
                    runLlm()
                    break
                case 'user_message':
                    runLlm()
                    break
                case 'assistant_message':
                    // nothing
                    break
                case 'tool_call':
                    // const tool = _lastStep.chatForLLM.tool_calls[0].function.name
                    // const args = JSON.parse(_lastStep.chatForLLM.tool_calls[0].function.arguments)
                    
                    // const { outputForLLM, displayForUser } = await callTool({tool: tool, args: args, addUserChat: addUserChat, addSystemChat: addSystemChat, clickableLink: clickableLink, showContent: showContent, walleUserId: walleUserId, walleApiKey: apiKey})
                    // const toolCallId = _lastStep.chatForLLM.tool_calls[0].id
                    
                    // addToolOutput(outputForLLM, toolCallId, displayForUser)
  
                    break
                case 'tool_output':
                    break
                default:
                    throw new Error("Unknown next step");        
            }
        }

        nextStep()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps])

    // useEffect(() => {
    //   if (!hasRun.current) {
    //     addStep({
    //       type: 'assistant_message',
    //       display: <StartingOptions onSelectOption={addUserChat} />,
    //       chatForLLM: { role: 'user', content: 'What can I help you buy today?' }
    //     })
    //     hasRun.current = true
    //   }
    // }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (!hasRun.current) {
        for (let i = 0; i < 1; i++) {
          addStep({
            type: 'assistant_message',
            component: 'StartingOptions',
            state: {},
            // display: <StartingOptions onSelectOption={addUserChat} />,
            chatForLLM: { role: 'assistant', content: 'What can I help you buy today?' }
          });
        }
        hasRun.current = true;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const chatMessages = steps.map(step => StepRenderer(step)).filter(component => component != null)
    // const chatMessages = steps.filter(step => step.display != null).map(step => step.display)
    
    // for scrolling down on new message
    const messagesRef = useRef(null);
    useEffect(() => {
      if (messagesRef.current) {
        // Scroll to the bottom of the box
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    }, [chatMessages]);
    
    return(
      <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Column for mobile, row for desktop
        height: '100vh',
      }}
    >
      {/* Sidebar at top for mobile, side for desktop */}
      <SideBar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          backgroundColor: '#1e1e1e',
          flexGrow: 1,
          overflowY: 'hidden', // To prevent overflow on mobile
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            backgroundColor: '#121212',
            overflowY: 'auto',
            flexGrow: 1, // Take the remaining space
          }}
          ref={messagesRef}
        >
          {isOpen && <SlidingPane />}
          <MessagesBox onUserChat={addUserChat}>{chatMessages}</MessagesBox>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '10%',
            backgroundColor: '#1e1e1e',
            position: 'relative',
          }}
        >
          <MessageInput />
        </Box>
      </Box>
    </Box>
    
    )
}

const SideBar = () => {
  return (
    <Box
      sx={{
        width: { xs: '100%', md: '237px' }, // Full width on mobile
        backgroundColor: '#2C2C2E',
        padding: '16px',
        display: 'flex',
        flexDirection: { xs: 'row', md: 'column' }, // Row for top bar, column for side
        minWidth: { xs: '100%', md: '237px' }, // Full width on mobile
        justifyContent: { xs: 'center', md: 'flex-start' }, // Center content on mobile
      }}
    >
      {/* Sidebar content */}
      <h2 style={{ color: '#fff' }}>Buy Supplements</h2>
    </Box>
  );
};

  

const MessageInput = ({ disabled }) => {
  const [userMessage, updateUserMessage] = useState('');
  const { closePane } = useSlidingPane()

  const { addUserChat } = useSteps()

  const onSend = () => {
    if (userMessage.trim() === '') return;
    addUserChat(userMessage);
    updateUserMessage('');
    closePane()
  };

  return (
    <Box
        sx={{
          backgroundColor: '#2C2C2E',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          value={userMessage}
          onChange={(e) => {
            if (!disabled) updateUserMessage(e.target.value);
          }}
          disabled={disabled}
          placeholder="Type here..."
          variant="standard"
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !disabled) {
              onSend();
            }
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              color: '#fff',
              paddingLeft: '8px',
              paddingRight: '8px',
            },
          }}
          sx={{
            backgroundColor: '#3A3A3C',
            borderRadius: '24px',
          }}
        />
        <IconButton
          onClick={onSend}
          disabled={disabled || userMessage.trim() === ''}
          sx={{
            backgroundColor: '#3A3A3C',
            color: '#fff',
            marginLeft: '8px',
            borderRadius: '50%',
            padding: '8px',
          }}
        >
          <ArrowUpwardIcon />
        </IconButton>
      </Box>
  )
}
  

const MessagesBox = ({ children }) => {
  return (
    <Box
          sx={{
              flexGrow: 1,
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              overflowY: 'auto', // Enable vertical scrolling
              overflowX: 'hidden',
              flexBasis: '100%'
          }}
    >
      {children}
    </Box>
  );
};

export default PersonalShopper