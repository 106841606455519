import { useSteps } from "./StepsProvider";
import { useWalle } from "./WalleProvider";
import { useEffect } from "react";
import { useSlidingPane } from "./SlidingPaneProvider";
import { WALLE_BASE } from "./Urls";

const Walle = () => {
    const { addUserChat } = useSteps();
    const { appId, setWalleUserId, setCardLastFour, walleUserId } = useWalle();
    const { closePane } = useSlidingPane();
  
    const onComplete = (userId, cardLastFour) => {
      // Only run this if walleUserId is not set
      if (!walleUserId) {
        const userChat = 'I completed my Walle Onboarding';
        addUserChat(userChat);
  
        setWalleUserId(userId);
        setCardLastFour(cardLastFour);
        closePane()
      }
    };
  
    const link = `${WALLE_BASE}/authorize_app?app_id=${appId}`;
    const style = {
      width: '500px',
      minHeight: '100vh',
      margin: '0 auto',
      border: 'none',
      background: 'inherit'
    };

    const containerStyle = {
        margin: '0 auto',
        textAlign: 'center'
    }
  
    useEffect(() => {
      // Function to handle messages from the iframe
      function handleMessage(event) {
        // Check if the message is from the expected origin and the message type is correct
        if (event.data?.type === 'authorizationComplete') {
          onComplete(event.data.data.userId, event.data.data.cardLastFour);
        }
      }
  
      // Add the event listener only once
      window.addEventListener('message', handleMessage);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('message', handleMessage);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walleUserId]); // Depend on walleUserId to ensure the effect runs correctly
  
    return(
        <div style={containerStyle}>
            <iframe title="Authorize Walle" src={link} style={style} />
        </div>
    )
  };

export default Walle