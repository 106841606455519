import { useCart } from "./CartProvider";
import { useWalle } from "./WalleProvider";
import { useSteps } from "./StepsProvider";
import { useSlidingPane } from "./SlidingPaneProvider";
import { useState } from "react";
import WallePurchase from "./WallePurchase";
import { Button } from "@mui/material";

const ProductPageStyle = {
  display: 'flex',
  flexDirection: 'column', // Column layout for each store section
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  padding: '20px',
  gap: '20px',
};

const ProductContainerStyle = {
  width: '100%', // Adjust width as needed to fit row
  maxWidth: '300px', // Set a fixed width for each product card
  border: '1px solid #ccc',
  borderRadius: '10px',
  padding: '10px',
};

const ProductStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const productImgStyle = {
  width: '100px', // Fixed width for image
  height: 'auto', // Keeps aspect ratio
  marginRight: '20px', // Spacing between image and description
  flexShrink: '0', // Prevent image from shrinking
};

const productDescriptionStyle = {
  flex: '1', // Takes up the remaining space
  textAlign: 'left', // Ensure text aligns to the left
};

const StoreResultsStyle = {
  display: 'flex',
  flexWrap: 'wrap', // Allow wrapping to next line if necessary
  gap: '20px', // Space between each result
  justifyContent: 'flex-start', // Align items from start
  marginTop: '10px', // Space between store name/logo and results
};


const ReviewPurchase = ({ cart, onConfirm }) => {
  const { name, imageSrc, description, priceCents } = cart[0]
  
  return (
    <div>
    <h2>Please review your order</h2>
    <div style={ProductContainerStyle}>
    <h2>{name}</h2>
    <div style={ProductStyle}>
      <img src={imageSrc} style={productImgStyle} alt={name} />
      <div style={productDescriptionStyle}>
        <p>{description}</p>
        <p>${priceCents / 100.0}</p>
      </div>
    </div>
  </div>
  
  <Button
    onClick={onConfirm}
    variant="contained"
    // disabled={cvv.length !== 3 && cvv.length !== 4}
    sx={{
      display: 'block',
      backgroundColor: '#6200EA',
      color: '#fff',
      fontSize: '16px',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '24px',
      padding: '10px 36px',
      marginTop: '16px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
      '&:hover': {
        backgroundColor: '#3700B3',
        boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
      },
      '&:active': {
        backgroundColor: '#5600E8',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
      },
    }}
  >
    Continue
  </Button>
</div>
  )
}
  
export const CheckoutPage = () => {
     const { cart } = useCart()
     const { walleUserId, sessionToken } = useWalle()
     const { addSystemChat } = useSteps()
     const [confirmed, updateConfirmed] = useState(false)
    //  const [cvv, updateCvv] = useState('')
  
     
     const onConfirm = () => {
        if (!walleUserId) {
          addSystemChat('The user needs to complete Walle onboarding before completing the purchase')
        }

        updateConfirmed(true)

          // create a "purchase intent"
          // return the id and load it into a component
  
         // const response = await fetch(`http://localhost:8080/run_agent?user_id=${walleUserId}&api_key=${walleApiKey}`, {
          //   method: 'GET',  // Changed to GET
          //   headers: {
          //     'Content-Type': 'application/json',  // This header can be optional for GET requests, but it doesn't harm
          //   },
          // });
  
            // if (!response.ok) {
            //     outputForLLM = 'Purchase failed'
            // } else {
            //     outputForLLM = 'Purchase succeeded'
            // }
            // displayForUser = <AssistantChat content="Purchase succeeded is there anything else I can help you with?" />
     }

    //  const onConfirm = () => {
    //     const client = new MultiOnClient()
    //     await client.browse({
    //       cmd: "Go and buy this item",
    //       url: productUrl,
    //       walleSessionToken: token
    //     })
    //  }

    if (sessionToken) {
      return(<p>purchasing started</p>)
    }
  
     return (
      <>
        {!confirmed && <ReviewPurchase cart={cart} onConfirm={onConfirm}/>}
        {confirmed && <WallePurchase purchaseDescription='Shopping for one multivitamin' expectedPurchaseAmountCents={3335} />}
      </>
    );
    
}
  
export const SearchResults = ({ results }) => {
  const { addUserChat } = useSteps();
  const { closePane } = useSlidingPane();
  const { addToCart } = useCart();

  const onBuyNow = (result) => {
    addUserChat(`Buy ${result.name}`);
    addToCart(result);
    closePane();
  };

  const textCenter = {
    textAlign: 'center',
  };

  const display = results.map((store) => {
    const { results: storeResults, name, logo } = store;

    const resultsDisplay = storeResults.map((result, index) => {
      const { name, imageSrc, priceCents, productLink } = result;
      return (
        <div key={index} style={ProductContainerStyle}>
          <h2>{name}</h2>
          <div style={textCenter}>
            <img src={imageSrc} style={productImgStyle} alt={name} />
            <div style={productDescriptionStyle}>
              {/* <p>{description}</p> */}
              <p>${priceCents / 100.0}</p>
            </div>
          </div>
          <div style={textCenter}>
            <Button
              onClick={() => onBuyNow(result)}
              sx={{
                margin: '0 auto',
                display: 'inline',
                backgroundColor: '#6200EA',
                color: '#fff',
                fontSize: '16px',
                fontWeight: 'bold',
                textTransform: 'none',
                borderRadius: '24px',
                padding: '10px 24px',
                marginTop: '16px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  backgroundColor: '#3700B3',
                  boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
                },
                '&:active': {
                  backgroundColor: '#5600E8',
                  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              Buy Now
            </Button>
            <Button
              onClick={() => window.open(productLink, '_blank')}
              sx={{
                margin: '0 auto',
                display: 'inline',
                backgroundColor: '#9E9E9E',
                color: '#fff',
                fontSize: '16px',
                fontWeight: 'bold',
                textTransform: 'none',
                borderRadius: '24px',
                padding: '10px 24px',
                marginTop: '16px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  backgroundColor: '#757575',
                  boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
                },
                '&:active': {
                  backgroundColor: '#616161',
                  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              View Product
            </Button>
          </div>
        </div>
      );
    });

    return (
      <div key={name}>
        {/* <h3>{name}</h3> */}
        <img src={logo} alt={`${name} logo`} />
        <div style={StoreResultsStyle}>{resultsDisplay}</div>
      </div>
    );
  });

  return (
    <div>
      <h2>Search Results</h2>
      <div style={ProductPageStyle}>{display}</div>
    </div>
  );
};
